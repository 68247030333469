@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poetsen+One&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  font-family: "Poetsen One", sans-serif;
  font-weight: 400;
  font-style: normal;
  scroll-behavior: smooth;
}

.navbar-clip {
  width: 100%;
  height: 100px;
  clip-path: polygon(
    100% 100%,
    100% 29%,
    79% 20%,
    59% 25%,
    38% 30%,
    18% 24%,
    0 33%,
    0 100%,
    44% 100%
  );
}

@layer components {
  .active {
    @apply bg-primary px-4 py-2 rounded-3xl text-white;
  }
  .hover-links {
    @apply text-primary duration-200;
  }

  .gallery-image {
    @apply object-cover w-full h-[250px]
  }
  .footer-hover-links {
    @apply hover:text-secondary duration-300
  }
  .admin-links {
    @apply bg-gray-700 p-3 bg-opacity-50 rounded-lg
  }
  .bookings-table-th {
    @apply border text-left p-2 text-white bg-black
  }  
  .error-message {
    @apply text-xs text-red-500 mt-1 whitespace-break-spaces break-words
  }
}

.carousel .slide img {
  height: calc(100vh - 180px);
  object-fit: cover;
}

@keyframes gradient-hover {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.rez-btn {
  background: linear-gradient(to right, #f0c52c 50%, #40a578 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.2s ease-out;
}

.rez-btn:hover {
  background-position: left bottom;
}

/*Scrollbar*/
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #40a578;
  border-radius: 10px;
}
/*Scrollbar*/

.img-clip {
  clip-path: polygon(15% 0, 100% 0%, 85% 100%, 0% 100%);
}

.options-card-shadow {
  -webkit-box-shadow: 10px 10px 5px 0px rgba(240, 198, 44, 1);
  -moz-box-shadow: 10px 10px 5px 0px rgba(240, 198, 44, 1);
  box-shadow: 10px 10px 5px 0px rgba(240, 198, 44, 1);
}

.gallery-image {
  width: 100%;
  height: 250px;
  object-fit: cover;
  cursor: pointer;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 8px;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

.modal-image {
  max-width: 90vw;
  max-height: 90vh;
}

@keyframes blink-animation {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.blink {
  animation: blink-animation 1s ease-in-out 2;
}

/*Loading Spinner*/
.lds-roller {
  /* change color here */
  color: #1c4c5b
}
.lds-roller,
.lds-roller div,
.lds-roller div:after {
  box-sizing: border-box;
}
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7.2px;
  height: 7.2px;
  border-radius: 50%;
  background: currentColor;
  margin: -3.6px 0 0 -3.6px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 62.62742px;
  left: 62.62742px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 67.71281px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 70.90963px;
  left: 48.28221px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 70.90963px;
  left: 31.71779px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 67.71281px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 62.62742px;
  left: 17.37258px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12.28719px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.booked {
  background: red;
  color: white;
}

.table-container {
  height: calc(100vh - 200px); /* Ekran boyutuna göre yükseklik ayarlayın */
  overflow-y: auto;
}
