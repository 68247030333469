html, body, #root {
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
  }
  
  .App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .MainContent {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
